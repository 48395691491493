//
//
//
//
//
//
//

export default {
    name: 'Merchant',
    props: ['merchant'],
    head() {
        return {
            meta: [
                {
                    hid: 'robots',
                    name: 'robots',
                    content: 'noindex, nofollow'
                }
            ]
        }
    }
};
