import { render, staticRenderFns } from "./Product.vue?vue&type=template&id=48a08438&"
import script from "./Product.vue?vue&type=script&lang=js&"
export * from "./Product.vue?vue&type=script&lang=js&"
import style0 from "./Product.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SubNavi: require('/usr/src/app/components/Content/SubNavi.vue').default,ProductGallery: require('/usr/src/app/components/Product/ProductGallery.vue').default,SocialSharer: require('/usr/src/app/components/Partials/SocialSharer.vue').default,ProductSelectVariant: require('/usr/src/app/components/Product/ProductSelectVariant.vue').default,ColorpaletteProduct: require('/usr/src/app/components/Partials/Colorpalettes/ColorpaletteProduct.vue').default,ProductTabs: require('/usr/src/app/components/Product/ProductTabs.vue').default,PageElement: require('/usr/src/app/components/PageElement.js').default,ProductRelatedTeaserSlider: require('/usr/src/app/components/Product/ProductRelatedTeaserSlider.vue').default,CtaTeaser: require('/usr/src/app/components/Content/CtaTeaser.vue').default,DealerLocatorTeaser: require('/usr/src/app/components/Content/DealerLocatorTeaser.vue').default})
