var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full",class:{
        'form-autocomplete--error': _vm.error,
    }},[(_vm.label)?_c('label',{staticClass:"form-autocomplete__label",attrs:{"for":_vm.id}},[_vm._v("\n        "+_vm._s(_vm.label)+"\n        "),(_vm.required)?[_vm._v("*")]:_vm._e()],2):_vm._e(),_vm._v(" "),_c('div',{ref:"select",staticClass:"form-autocomplete",class:{ 'form-autocomplete--white': _vm.white }},[_c('multiselect',{ref:"multiselectRef",attrs:{"name":_vm.id,"allow-empty":_vm.required,"show-no-results":false,"track-by":_vm.trackedBy ? _vm.trackedBy : undefined,"label":_vm.optionLabel ? _vm.optionLabel : undefined,"options":_vm.items,"searchable":_vm.searchable,"show-labels":false,"preserve-search":true,"placeholder":_vm.placeholder,"hideSelected":false,"clearable":false,"allowEmpty":false,"preselectFirst":true,"max-height":400},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
    var option = ref.option;
return [(option.value)?_c('span',{attrs:{"id":(_vm._uid + "-loc-aria-" + (_vm.items.indexOf(option)))}},[_vm._v(_vm._s(option.text))]):_vm._e()]}},{key:"option",fn:function(ref){
    var option = ref.option;
return [(option.value)?_c('span',{attrs:{"id":(_vm._uid + "-loc-aria-" + (_vm.items.indexOf(option)))}},[_vm._v(_vm._s(option.text))]):_vm._e()]}},{key:"noOptions",fn:function(ref){
    var option = ref.option;
return [_c('span',[_vm._v(_vm._s(_vm.$t('form.autocomplete.no_results')))])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._v(" "),_vm._v(" "),_vm._v(" "),_c('template',{slot:"caret"},[_c('div',{staticClass:"multiselect__select",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"mousedown":function($event){$event.stopPropagation();$event.preventDefault();}}})])],2)],1),_vm._v(" "),(_vm.error)?_c('div',{staticClass:"form-autocomplete__error"},[_vm._t("errors",[_vm._v("Please check your input")])],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }