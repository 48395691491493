//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { WATCHABLE, SHOWPRICES } from '../../constants/index';
import { mapActions, mapGetters } from 'vuex';
import ProductRelatedTeaserSlider from './ProductRelatedTeaserSlider.vue';
import ProductGallery from './ProductGallery.vue';
import SocialSharer from '../Partials/SocialSharer.vue';
import ProductSelectVariant from './ProductSelectVariant.vue';
import ColorpaletteProduct from '../Partials/Colorpalettes/ColorpaletteProduct.vue';
import ProductTabs from './ProductTabs.vue';
import SubNavi from '../Content/SubNavi.vue';
import { includeFrameInPrice } from '../../services/productAdjustmens.js';
import { createMetaItem } from '../../services/meta.util';
const isProductUnique = (item, index, arr) => index === arr.findIndex((elem) => elem.slug === item.slug);

export default {
    name: 'Product',
    mixins: [require('../../mixins/product_range.js').default, require('../../mixins/pricing.js').default],
    props: ['landingPageUrl'],
    components: {
        ProductTabs,
        ColorpaletteProduct,
        ProductSelectVariant,
        SocialSharer,
        ProductGallery,
        ProductRelatedTeaserSlider,
        SubNavi,
    },
    head() {
        const images =
            this.variant && this.variant.galleryImages
                ? this.variant.galleryImages.map((image) => {
                      return this.$mapImageData(image?.gallery)?.baseImg;
                  })
                : [];

        let prices = [];
        let variantPrice = null;
        for (let variant of this.product.variants) {
            variantPrice = this.getVariantDetailPrice(variant);
            if (variantPrice) {
                const price = parseFloat(((variantPrice.price / 100) * (100 + this.tax)).toFixed(2));
                prices.push(price);
            }
        }
        let minPrice = Math.min(...prices);
        let maxPrice = Math.max(...prices);
        const CURRENCY_MAPPING = {
            '£': 'GBP',
            '€': 'EUR',
            $: 'USD',
        };

        return {
            meta: [createMetaItem('description', this.product?.metaDescription)],
            script: [
                {
                    type: 'application/ld+json',
                    json: {
                        '@context': 'https://schema.org/',
                        '@type': 'Product',
                        name: this.productName,
                        image: images,
                        description: this.product.imageText,
                        brand: {
                            '@type': 'Brand',
                            name: 'Bette',
                        },
                        category: this.product.category.name,
                        offers: {
                            '@type': 'AggregateOffer',
                            lowPrice: minPrice.toFixed(2),
                            highPrice: maxPrice.toFixed(2),
                            priceCurrency: variantPrice && CURRENCY_MAPPING[variantPrice.currency] ? CURRENCY_MAPPING[variantPrice.currency] : 'EUR',
                        },
                    },
                },
            ],
        };
    },
    data() {
        return {
            WATCHABLE: WATCHABLE,
            CONFIGURABLE: true,
            SHOWPRICES: SHOWPRICES,
            tax: 0,
            reloadSlider: false,
            showSubnavi: false,
            relatedItems: [],
        };
    },
    computed: {
        ...mapGetters({
            product: 'getStoredProduct',
            productName: 'getStoredProductName',
            variant: 'getStoredProductVariant',
            siteProperties: 'getStoredSiteProperties',
        }),
        isNew() {
            return this.variant?.isNew || this.product?.isNew;
        },
        priceWithTax() {
            const bestPrice = this.getVariantDetailPrice(this.variant);

            if (bestPrice) {
                const format = this.$i18n.locale;
                const isGBP = bestPrice.currency === '£';

                if (this.addedPrice > 0) {
                    const price = ((bestPrice.price + this.addedPrice) / 100) * (100 + this.tax);

                    if (isGBP) {
                        // Für GBP: Symbol vorne, aber deutsche Zahlenformatierung
                        return `£${price.toLocaleString(format, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            useGrouping: true,
                        })}`;
                    }

                    // Für EUR: Normale Locale-Formatierung
                    return price.toLocaleString(format, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currency: 'EUR',
                    });
                }

                const price = (bestPrice.price / 100) * (100 + this.tax);

                if (isGBP) {
                    // Für GBP: Symbol vorne, aber deutsche Zahlenformatierung
                    return `£${price.toLocaleString(format, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        useGrouping: true,
                    })}`;
                }

                // Für EUR: Normale Locale-Formatierung
                return price.toLocaleString(format, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'EUR',
                });
            }
            return null;
        },
        combinableItems() {
            return this.product && this.product.combinable
                ? this.product.combinable.filter((item) => {
                      return this.isInProductRange(item);
                  })
                : [];
        },
        isInWatchList() {
            return this.$store.getters.getWatchList.find((o) => this.variant && o.id === this.variant?.id);
        },
        dealerLocatorDeeplink() {
            const merchantSearchSlug = this.siteProperties?.merchant_search_page?.id;

            if (!merchantSearchSlug) {
                return '';
            }

            const query = [
                ['cat', this.product?.category?.id],
                ['product', this.product?.id],
                ['variant', this.variant?.id],
            ]
                .filter((item) => !!item[1])
                .map((item) => `${item[0]}=${item[1]}`)
                .join('&');

            return `${this.$getLocalizedSlug(merchantSearchSlug)}?${query}`;
        },

        // FIXME: Code duplication with Configurator.vue#variantModelUrl
        activeVariantHasDrawingMeasure() {
            if (!this.variant) {
                return null;
            }

            const downloads = this.variant?.downloads || [];
            const gltfFile = downloads.find((item) => item.filePath.filename.match('.gltf$'));

            if (!gltfFile) {
                return null;
            }

            return `${this.$config.pimcoreUrl}${gltfFile.filePath.fullpath}`;
        },
    },
    methods: {
        ...mapActions(['setStoredProductVariant']),
        async getRelatedItems() {
            let items = [...(this.product?.extras ?? []), ...(this.product?.accessories ?? []), ...(this.variant?.accessories ?? []), ...(this.variant?.extras ?? [])];

            for (let i = 0; i < items.length; i++) {
                if (items[i].__typename && items[i].__typename !== 'object_Product') {
                    let product = await this.$nuxt.context.app.api.product.getProductByVariantId(items[i].id);
                    if (product) {
                        product.variants = [items[i]];
                        items[i] = product;
                    }
                } else if (items[i].parentId) {
                    let product = await this.$nuxt.context.app.api.product.getProductById(items[i].parentId);
                    if (product) {
                        product.variants = [items[i]];
                        items[i] = product;
                    }
                }
            }

            items = items
                .filter((product, index, arr) => {
                    return !!product && !!product.slug && isProductUnique(product, index, arr) && this.isInProductRange(product);
                })
                .sort((a, b) => {
                    const p1 = a.parentPrio ? a.parentPrio : a.prio;
                    const p2 = b.parentPrio ? b.parentPrio : b.prio;
                    return p1 - p2;
                });

            return items;
        },
        async changeVariant(val) {
            this.reloadSlider = true;

            this.setStoredProductVariant(val, true);

            this.relatedItems = await this.getRelatedItems();

            this.$nextTick(() => {
                this.reloadSlider = false;
            });
        },
        toggleWatchList() {
            if (this.isInWatchList) {
                this.$store.dispatch('removeFromWatchList', {
                    object: Object.assign({}, this.variant),
                });
            } else {
                this.$store.dispatch('addToWatchList', {
                    object: Object.assign({}, this.variant),
                });
                this.$dtoast.pop({
                    preset: 'success',
                    heading: this.$t('watchlist.add.headline'),
                    content: this.$t('watchlist.add.text'),
                    width: '100%',
                    icon: 'link link--icon link--pin',
                    containerSideOffset: '25px',
                });
            }
        },
        getSocialMediaImage() {
            let image = null;
            try {
                image = this.product.images[0].gallery.resolutions[0].url;
            } catch (e) {
                //console.warn('Error in getSocialMediaImage()', e);
            }
            return image;
        },
        getColorCategoryName(category) {
            let categoryName = category[this.$i18n.locale] || category['en'] || category.name;
            if (category.name === 'Stofffarben') {
                return `
                    <span>
                        ${categoryName}
                        <span class="text-sm">(${this.$t('configurator.pricing.fabric_not_included')})</span>
                    </span>
                `;
            }
            if (category.name === 'Gestellfarben' && !includeFrameInPrice(this.product.externalKey)) {
                return `
                    <span>
                        ${categoryName}
                        <span class="text-sm">(${this.$t('configurator.pricing.frame_not_included')})</span>
                    </span>
                `;
            }
            return categoryName;
        },
    },
    async fetch() {
        const currentProductRangeCountry = this.getSelectedProductRange().toLowerCase();
        this.tax = await this.$nuxt.context.app.api.product.getTax(currentProductRangeCountry);
        this.relatedItems = await this.getRelatedItems();
    },
};
